import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initParamsFilterUser: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

const UserSlice = createSlice({
  name: 'user',
  initialState: {
    paramsFilter: initParamsFilterUser,
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterUser;
    },
  },
});

export const { setParamFilter, resetParamFilter } = UserSlice.actions;

export default UserSlice.reducer;
