import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';
export const initParamsFilterProduct: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
  status: 2,
};

const ProductSlice = createSlice({
  name: 'product',
  initialState: {
    paramsFilter: initParamsFilterProduct,
    categoryActive: [],
    tagFilters: {},
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterProduct;
    },
  },
});

export const { setParamFilter, resetParamFilter } = ProductSlice.actions;

export default ProductSlice.reducer;
