import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Routes, Route, Router } from 'react-router-dom';
import loadable from '@loadable/component';
import 'antd/dist/antd.css';
import 'assets/scss/_style.scss';
import { history } from 'routes/history';
import { messaging, requestForToken } from 'store/common/firebase';
import { onMessage } from 'firebase/messaging';
import { useAppSelector, useAppDispatch } from 'hooks/hookStore';
import { setDeviceToken } from 'store/common/commonSlice';
import { MessageModel } from 'pages/chat/model/ChatDetailModel';
import { TYPE_CLOUD_MESSAGE } from 'store/common/constants';
import { increaseNotify, setChatMessageList, setNotificationList } from 'pages/chat/store/chatSlice';

const LoginPage = loadable(() => import('pages/auth/views/Login'));
const DefaultLayout = loadable(() => import('layouts/DefaultLayout'));

const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};

export const App: React.FC = () => {
  const messageList = useAppSelector((state) => state.chat.chatMessageList);
  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      const token = await requestForToken();
      // console.log('token', token);

      dispatch(setDeviceToken(token));
    })();

    onMessage(messaging, (payload) => {
      // console.log('payload', payload);

      const data = payload?.data?.data;
      if (data) {
        const message = JSON.parse(data) as MessageModel;
        if (message) dispatch(setChatMessageList([...messageList, message]));

        dispatch(increaseNotify());
      }
      // const listNoti = [];
      // listNoti.push(payload.notification);
      dispatch(setNotificationList(payload.notification));
    });
  }, []);

  return (
    <CustomRouter history={history}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/*" element={<DefaultLayout />} />
      </Routes>
    </CustomRouter>
  );
};
