import { createSlice } from '@reduxjs/toolkit';

const ChatSlice = createSlice({
  name: 'chat',
  initialState: {
    chatMessageList: [],
    countNotify: 0,
    notificationList: null,
  },
  reducers: {
    setChatMessageList(state, action) {
      state.chatMessageList = action.payload;
    },

    setNotificationList(state, action) {
      state.notificationList = action.payload;
    },

    setCountNotify(state, action) {
      state.countNotify = action.payload;
    },
    increaseNotify(state) {
      state.countNotify++;
    },
    decreaseNotify(state) {
      state.countNotify--;
    },
  },
});

export const { setChatMessageList, setCountNotify, increaseNotify, decreaseNotify, setNotificationList } =
  ChatSlice.actions;

export default ChatSlice.reducer;
