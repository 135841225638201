import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import auth from 'pages/auth/store/AuthSlice';
import clinic from 'pages/clinic/store/ClinicSlice';
import { default as customer, default as user } from 'pages/customers/store/CustomersSlice';
import order from 'pages/orders/store/OrderSlice';
import booking from 'pages/booking/store/BookingSlice';
import surveys from 'pages/surveyManagement/store/SurveysSlice';
import product from 'pages/product/store/productSlice';
import common from './common/commonSlice';
import service from 'pages/serviceList/store/ServiceSlice';
import news from 'pages/news/store/newsSlice';
import account from 'pages/users/store/UserSlice';
import role from 'pages/role/store/RoleSlice';
import { apiStore } from './storeApi';
import beautyTip from 'pages/news/store/beautyTipSlice';
import qa from 'pages/news/store/qaSlice';
import category from 'pages/product/store/CategorySlice';
import comment from 'pages/product/store/CommentSlice';
import chat from 'pages/chat/store/chatSlice';

const rootReducer = combineReducers({
  [apiStore.reducerPath]: apiStore.reducer,
  common,
  auth,
  customer,
  clinic,
  user,
  surveys,
  product,
  service,
  order,
  booking,
  news,
  role,
  account,
  beautyTip,
  qa,
  category,
  comment,
  chat,
});

const middlewareHandler = (getDefaultMiddleware: any) => {
  const middlewareList = [...getDefaultMiddleware()];
  return middlewareList;
};
//API slice Will include automatically generated redux reducer And a custom middleware
export const rootStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware).concat(apiStore.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootStore.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof rootStore.dispatch;

setupListeners(rootStore.dispatch);
// export default rootReducer;
