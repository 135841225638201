import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';
export const initParamsFilterComment: Partial<ParamsFilterModelDefault> = {
  pageIndex: '1',
  pageSize: '10',
  createTime: '',
};

const CommentSlice = createSlice({
  name: 'comment',
  initialState: {
    paramsFilter: initParamsFilterComment,
    tagFilters: {},
    allDataFilter: {},
    dataFilter: {},
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterComment;
    },
    setDataFilter(state, action) {
      state.dataFilter = action.payload;
    },
    resetDataFilter(state, action) {
      state.dataFilter = action.payload;
    },
    setAllDataFilter(state, action) {
      state.allDataFilter = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setAllDataFilter, setDataFilter, resetDataFilter } =
  CommentSlice.actions;

export default CommentSlice.reducer;
