import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initParamsFilterService: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

const beautyTip = createSlice({
  name: 'beautyTip',
  initialState: {
    paramsFilter: initParamsFilterService,
    serviceSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterService;
    },
    setServiceSelectedRow(state, action) {
      state.serviceSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setServiceSelectedRow } = beautyTip.actions;

export default beautyTip.reducer;
