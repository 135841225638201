import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initParamsFilterBooking: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

export interface initDataFilterBooking {
  [key: string]: string | number;
}

const BookingSlice = createSlice({
  name: 'booking',
  initialState: {
    paramsFilter: initParamsFilterBooking,
    dataFilter: {},
    allDataFilter: {},
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterBooking;
    },
    setDataFilter(state, action) {
      state.dataFilter = action.payload;
    },
    resetDataFilter(state, action) {
      state.dataFilter = action.payload;
    },
    setAllDataFilter(state, action) {
      state.allDataFilter = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setDataFilter, resetDataFilter, setAllDataFilter } =
  BookingSlice.actions;

export default BookingSlice.reducer;
