import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';
export const initParamsFilterSurvey: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
  sort: '',
  direction: '',
};

const SurveysSlice = createSlice({
  name: 'surveys',
  initialState: {
    paramsFilter: initParamsFilterSurvey,
  },
  reducers: {
    setParamFilterSurvey(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilterSurvey(state) {
      state.paramsFilter = initParamsFilterSurvey;
    },
  },
});

export const { setParamFilterSurvey, resetParamFilterSurvey } = SurveysSlice.actions;

export default SurveysSlice.reducer;
