import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initParamsFilterOrder: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

const OrderSlice = createSlice({
  name: 'order',
  initialState: {
    paramsFilter: initParamsFilterOrder,
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterOrder;
    },
  },
});

export const { setParamFilter, resetParamFilter } = OrderSlice.actions;

export default OrderSlice.reducer;
