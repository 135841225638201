import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initNewsFilterProduct: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

const qaSlice = createSlice({
  name: `qa`,
  initialState: {
    paramsFilter: initNewsFilterProduct,
    categoryActive: [],
    tagFilters: {},
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initNewsFilterProduct;
    },
  },
});

export const { setParamFilter, resetParamFilter } = qaSlice.actions;

export default qaSlice.reducer;
