import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initParamsFilterCategory: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

const CategorySlice = createSlice({
  name: 'category',
  initialState: {
    paramsFilter: initParamsFilterCategory,
    categorySelectedRows: [],
  },
  reducers: {
    setParamFilterCategory(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilterCategory(state) {
      state.paramsFilter = initParamsFilterCategory;
    },
    setCategorySelectedRow(state, action) {
      state.categorySelectedRows = action.payload;
    },
  },
});

export const { setParamFilterCategory, resetParamFilterCategory, setCategorySelectedRow } = CategorySlice.actions;

export default CategorySlice.reducer;
