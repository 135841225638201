import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initParamsFilterRole: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

const RoleSlice = createSlice({
  name: 'role',
  initialState: {
    paramsFilter: initParamsFilterRole,
    roleSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterRole;
    },
    setRoleSelectedRow(state, action) {
      state.roleSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setRoleSelectedRow } = RoleSlice.actions;

export default RoleSlice.reducer;
