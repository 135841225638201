import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initParamsFilterCustomer: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

const CustomersSlice = createSlice({
  name: 'customers',
  initialState: {
    paramsFilter: initParamsFilterCustomer,
    customersSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterCustomer;
    },
    setCustomerSelectedRow(state, action) {
      state.customersSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setCustomerSelectedRow } = CustomersSlice.actions;

export default CustomersSlice.reducer;
