import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initParamsFilterClinic: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
  // status: 1, be chưa trả ra chỗ status này truyền giá trị bao nhiêu để lấy all, mặc định đang trả active
};

const ClinicSlice = createSlice({
  name: 'clinic',
  initialState: {
    paramsFilter: initParamsFilterClinic,
    clinicSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterClinic;
    },
    setClinicSelectedRow(state, action) {
      state.clinicSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setClinicSelectedRow } = ClinicSlice.actions;

export default ClinicSlice.reducer;
