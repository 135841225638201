import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAq3UpB-VNxnFdxqIoHb_K2otxwL794oFU',
  authDomain: 'rohto-acnes.firebaseapp.com',
  projectId: 'rohto-acnes',
  storageBucket: 'rohto-acnes.appspot.com',
  messagingSenderId: '326674153622',
  appId: '1:326674153622:web:b88241f05d208af7f142b7',
  measurementId: 'G-8ZKXR7SFBP',
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestForToken = () => {
  return Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      return getToken(messaging, {
        vapidKey: 'BKdTK5j5bjUDyi56O6aDQ67MecnE45ISzj2uHyhzI26YhBKfFPrlHLG4s_o9Ei8CDXWGPltLmCLbZ_XCBzzFqUw',
      })
        .then((currentToken: string) => {
          if (currentToken) {
            return currentToken;
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
      console.log('Notification permission granted.');
    }
  });
};

export { messaging };
